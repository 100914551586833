import { Modal, TBaseModalProps } from '@/components/Modal';
import { ResendConfirmEmailForm } from './ResendConfirmEmailForm';
import { useCaptcha } from '../recaptcha';
export const ResendConfirmEmailModal = ({
  open,
  onSetOpen,
  onSuccess,
  captchaData
}: TBaseModalProps & {
  onSuccess: () => void;
  captchaData: ReturnType<typeof useCaptcha>;
}) => <Modal open={open} onSetOpen={onSetOpen} ctaButtonText={'Resend Confirmation Email'} title={'Resend Confirmation Email'} content={<ResendConfirmEmailForm onSuccess={onSuccess} captchaData={captchaData} />} data-sentry-element="Modal" data-sentry-component="ResendConfirmEmailModal" data-sentry-source-file="ResendConfirmEmailModal.tsx" />;