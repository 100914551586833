'use client';

import { logger } from '@/libs/utils/logger';
import { Root, Field, Label, Message, Control, Submit } from '@radix-ui/react-form';
import styles from '../form.styles.module.css';
import { authUserByEmail } from '@/libs/api/login';
import { LegacyRef, ReactEventHandler, useEffect, useState } from 'react';
import { CaptchaIframe, ChallengeCaptchaModal, RECAPTCHA_ACTIONS, useCaptcha } from '@/features/recaptcha';
import { EmailLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/email-login.dto';
import { UserApiErrorV1 } from '@/libs/api/eagle-api-wrapper/api-gateway';
import { ButtonCallToAction } from '@/components/ButtonCallToAction';
import { TAuthFormAction } from '../EntryForm';
import { GoogleAuthButton } from '@/features/auth/GoogleAuthButton';
import { FacebookAuthButton } from '@/features/auth/FacebookAuthButton';
export type TSignInUpEmailFormProps = {
  action: TAuthFormAction;
  errors: string[];
};
export const SignInUpEmailForm = ({
  action,
  errors
}: TSignInUpEmailFormProps) => {
  return <Root className={styles.Root} action={action} data-sentry-element="Root" data-sentry-component="SignInUpEmailForm" data-sentry-source-file="index.tsx">
			{/* SOCIAL LOGINS PLACEHOLDER*/}
			<Field className={styles.Field} name='email' data-sentry-element="Field" data-sentry-source-file="index.tsx">
				<div style={{
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between'
      }}>
					<Label className={styles.Label} data-sentry-element="Label" data-sentry-source-file="index.tsx">Continue with Email</Label>
				</div>
				<GoogleAuthButton data-sentry-element="GoogleAuthButton" data-sentry-source-file="index.tsx" />
				<FacebookAuthButton data-sentry-element="FacebookAuthButton" data-sentry-source-file="index.tsx" />
				<Control asChild data-sentry-element="Control" data-sentry-source-file="index.tsx">
					<input className={styles.Input} name='email' type='email' placeholder='your.email@mail.com' />
				</Control>
				{errors.length ? <p className={styles.Error}>Email must be valid</p> : null}
			</Field>
			<Submit asChild data-sentry-element="Submit" data-sentry-source-file="index.tsx">
				<ButtonCallToAction text='Continue' addClass={styles.ContinueButton} data-sentry-element="ButtonCallToAction" data-sentry-source-file="index.tsx"></ButtonCallToAction>
			</Submit>
			{/* PRIVACY POLICY PLACEHOLDER*/}
		</Root>;
};