import {
	AuthResponseDTO,
	AuthResponsePayloadDto,
} from '@arkadium/eagle-user-client/dist/types/api/v1/dto/auth-response.dto';
import { EmailRegistrationDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/email-registration.dto';
import { RegisterResponseDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/register-response.dto';
import { EmailLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/email-login.dto';
import { AppleLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/apple-login.dto';
import { FacebookLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/facebook-login.dto';
import { GoogleLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/google-login.dto';
import { UsatLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/usat-login.dto';
import { HsnLoginDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/hsn-login.dto';
import { ResendConfirmationDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/resend-confirmation.dto';
import { EmailConfirmationDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/email-confirmation.dto';
import { PasswordComplexityDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/password-complexity.dto';
import { ChangePasswordDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/change-password.dto';
import { RequstResetPasswordDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/request-reset-password.dto';
import { ConfirmResetPasswordDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/confirm-reset-password.dto';
import { fetchJson } from '@/libs/api/eagle-api-wrapper/utils';

class Deffered<T> {
	promise: Promise<T>;
	resolve: (options: T) => void = () => null;
	reject: (err: Error) => void = () => null;

	constructor() {
		this.promise = new Promise((res, rej) => {
			this.resolve = res;
			this.reject = rej;
		});
	}
}

export type AuthToken = Pick<AuthResponseDTO, 'access_token' | 'refresh_token' | 'expires_in'>;
export class AuthApi {
	private server?: URL;
	private saveCredentialCallback?: (data: AuthToken) => void;
	private tokenDeffered: Deffered<string> | null = null;
	setServer(server: URL) {
		if (!server) {
			throw new Error('Please, provide server Url');
		}
		this.server = server;
	}

	public setSaveCredentialCallback(callback: (data: AuthToken) => void) {
		this.saveCredentialCallback = callback;
	}

	private saveCredential(data: AuthToken) {
		if (this.saveCredentialCallback) {
			this.saveCredentialCallback(data); //can reuse this callback to update data on the client-side as it is implemented in eagle-user-client via sessionStorage.update method
		}
		return data;
	}

	private makeRequest(
		method: string,
		url: string,
		body: Record<any, any>,
		token: string = '',
		captchaToken: string = '',
		captchaMode?: string,
	) {
		if (!this.server) {
			throw new Error('No server url provided');
		}

		const headers = new Headers({ 'Content-Type': 'application/json' });

		if (token) {
			headers.append('Authorization', `Bearer ${token}`);
		}

		if (captchaToken) {
			headers.append('recaptcha', captchaToken);
		}

		if (captchaMode) {
			headers.append('recaptcha-mode', captchaMode);
		}

		return fetchJson(this.server.toString() + url, {
			method,
			headers,
			body: JSON.stringify({ ...body }),
		});
	}

	private doPost(url: string, body: Record<any, any>, token: string = '') {
		const captchaToken = body.captchaToken || '';
		const captchaMode = body.captchaMode || undefined;

		delete body.captchaToken;
		delete body.captchaMode;
		return this.makeRequest('POST', url, body, token, captchaToken, captchaMode);
	}

	registerViaEmail(data: EmailRegistrationDTO): Promise<RegisterResponseDto> {
		return this.doPost('auth/register', {
			...data,
			registrationPlaceUrl: data.registrationPlaceUrl ? data.registrationPlaceUrl.toString() : undefined,
		});
	}

	loginViaEmail(data: EmailLoginDTO): Promise<AuthToken> {
		return this.doPost('auth/login', {
			...data,
			registrationPlaceUrl: data.registrationPlaceUrl ? data.registrationPlaceUrl.toString() : undefined,
		}).then((d: AuthToken) => this.saveCredential(d));
	}

	loginViaApple(data: AppleLoginDTO): Promise<AuthResponsePayloadDto> {
		return this.doPost('auth/login/apple', {
			...data,
			registrationPlaceUrl: data.registrationPlaceUrl ? data.registrationPlaceUrl.toString() : undefined,
		}).then((d: AuthResponseDTO) => {
			this.saveCredential(d);
			return d.payload;
		});
	}

	loginViaFacebook(data: FacebookLoginDTO): Promise<AuthResponsePayloadDto> {
		return this.doPost('auth/login/facebook', {
			...data,
			registrationPlaceUrl: data.registrationPlaceUrl ? data.registrationPlaceUrl.toString() : undefined,
		}).then((d: AuthResponseDTO) => {
			this.saveCredential(d);
			return d.payload;
		});
	}

	loginViaGoogle(data: GoogleLoginDTO): Promise<AuthResponsePayloadDto> {
		return this.doPost('auth/login/google', {
			...data,
			registrationPlaceUrl: data.registrationPlaceUrl ? data.registrationPlaceUrl.toString() : undefined,
		}).then((d: AuthResponseDTO) => {
			this.saveCredential(d);
			return d.payload;
		});
	}

	loginViaUsat(data: UsatLoginDTO): Promise<AuthResponsePayloadDto> {
		return this.doPost('auth/login/usat', {
			...data,
			registrationPlaceUrl: data.registrationPlaceUrl ? data.registrationPlaceUrl.toString() : undefined,
		}).then((d: AuthResponseDTO) => {
			this.saveCredential(d);
			return d.payload;
		});
	}

	loginViaHsn(data: HsnLoginDto): Promise<AuthResponsePayloadDto> {
		return this.doPost('auth/login/hsn', {
			...data,
			registrationPlaceUrl: data.registrationPlaceUrl ? data.registrationPlaceUrl.toString() : undefined,
		}).then((d: AuthResponseDTO) => {
			this.saveCredential(d);
			return d.payload;
		});
	}

	resendConfirmation(data: ResendConfirmationDTO): Promise<void> {
		return this.doPost('auth/email/resend-confirmation', {
			...data,
		});
	}

	confirmUser(data: EmailConfirmationDTO): Promise<AuthResponseDTO> {
		return this.doPost('auth/email/confirm', {
			...data,
		});
	}

	checkPasswordComplexity(data: PasswordComplexityDTO): Promise<{ result: boolean }> {
		return this.doPost('auth/password/check-complexity', {
			...data,
		});
	}

	changePassword(data: ChangePasswordDTO, token: string): Promise<void> {
		// return this.getToken(authData).then((token) => {
		return this.doPost(
			'auth/password/change',
			{
				...data,
			},
			token,
		);
		// });
	}

	requestResetPassword(data: RequstResetPasswordDTO): Promise<void> {
		return this.doPost('auth/password/reset/request', {
			...data,
			resetPasswordConfirmationFormUrl: data.resetPasswordConfirmationFormUrl
				? data.resetPasswordConfirmationFormUrl.toString()
				: undefined,
			registrationPlaceUrl: data.registrationPlaceUrl ? data.registrationPlaceUrl.toString() : undefined,
		});
	}

	confirmResetPassword(data: ConfirmResetPasswordDTO): Promise<void> {
		return this.doPost('auth/password/reset/confirm', {
			...data,
		});
	}

	getFacebookButton(): string {
		return 'https://login.arkadium.com/arenax-connect/azure.facebook.next.index.html';
	}

	getGoogleButton(): string {
		return 'https://login.arkadium.com/arenax-connect/azure.google.next.index.html';
	}
	private refreshToken(data: AuthToken): Deffered<string> {
		this.tokenDeffered = new Deffered<string>();
		fetchJson(this.server?.toString() + 'auth/refresh-token', {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${data.access_token}`,
			},
			body: JSON.stringify({
				refreshToken: data.refresh_token,
			}),
		})
			.then((data: AuthResponseDTO) => {
				this.tokenDeffered?.resolve(data.access_token);
				this.tokenDeffered = null;
			})
			.catch((err: any) => {
				this.tokenDeffered?.reject(err);
				this.tokenDeffered = null;
			});

		return this.tokenDeffered;
	}
	getToken(data: AuthToken): Promise<string> {
		// Token refresh is in progress
		if (this.tokenDeffered) {
			return this.tokenDeffered.promise;
		}

		// If token is in 30 min from expiration time
		const willExpireSoon = data.expires_in * 1000 <= Date.now() + 1.8e6;

		if (willExpireSoon) {
			this.tokenDeffered = this.refreshToken(data);
			return this.tokenDeffered.promise;
		}

		if ((data.expires_in - 10) * 1000 > Date.now()) {
			return Promise.resolve(data.access_token);
		}

		if (data.access_token === '' || data.refresh_token === '') {
			return Promise.reject(new Error('Refresh token is empty!'));
		}

		this.tokenDeffered = this.refreshToken(data);

		return this.tokenDeffered.promise;
	}
}
