import { useEffect, useRef, useState } from 'react';
enum EBUTTON_TYPES {
  EAGLE_BUTTON = 'EAGLE_BUTTON',
}
const GOOGLE_APP_ID_MESSAGE_TYPE = 'GOOGLE_APP_ID';
export const GoogleAuthButton = () => {
  const googleIframeRef = useRef<HTMLIFrameElement>(null);
  const [isGoogleButtonLoad, setIsGoogleButtonLoad] = useState<boolean>(false);
  const googleAuthButtonUrl = process.env.GOOGLE_AUTH_BUTTON_URL;
  const postMessageToButton = ({
    type,
    target,
    origin,
    payload = {}
  }: {
    type: string;
    target: HTMLIFrameElement;
    origin: string;
    payload?: {
      googleAppId?: string;
      innerWidth?: number;
    };
  }) => {
    target?.contentWindow?.postMessage({
      type,
      payload
    }, origin);
  };
  useEffect(() => {
    // Post message to db and google
    if (googleIframeRef?.current && isGoogleButtonLoad) {
      postMessageToButton({
        type: EBUTTON_TYPES.EAGLE_BUTTON,
        target: googleIframeRef.current,
        origin: googleAuthButtonUrl,
        payload: {
          innerWidth: window.innerWidth
        }
      });
      postMessageToButton({
        type: GOOGLE_APP_ID_MESSAGE_TYPE,
        target: googleIframeRef.current,
        origin: googleAuthButtonUrl,
        payload: {
          googleAppId: process.env.DEFAULT_GOOGLE_APP_ID
        }
      });
    }
  }, [googleAuthButtonUrl, isGoogleButtonLoad]);
  return <iframe ref={googleIframeRef} onLoad={() => setIsGoogleButtonLoad(true)} src={googleAuthButtonUrl} title='google button iframe' id='google-button-iframe' height='40px' width='220px' data-sentry-component="GoogleAuthButton" data-sentry-source-file="index.tsx" />;
};