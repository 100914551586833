import { AuthApi as authV1 } from './api/v1/auth.api';
import { UserManagmentApi as userV1 } from './api/v1/user-managment.api';
import { DictionaryApi as dictionaryV1 } from './api/v1/dictionary.api';
import { UserFavoriteGameApi as userFavoriteGameV1 } from './api/v1/user-favorite-game.api';
import { EventsMap, Events, WidgetStateChangedEvent, AuthStateChangedEvent } from './events';
import { UserGameDataApi, UserGameDataApiInitParams } from './api/v1/user-game-data.api';
import { EventEmitter } from './event-emitter';

export { authV1 as AuthApiV1 };
export { userV1 as UserManagmentApiV1 };
export { dictionaryV1 as DictionaryApiV1 };
export { userFavoriteGameV1 as UserFavoriteGameApiV1 };
export { UserApiError as UserApiErrorV1 } from './api/v1/errors';

export enum Api {
	v1 = 1,
}

export type ApiSet = {
	auth: authV1;
	managment: userV1;
	dictionary: dictionaryV1;
	userFavoriteGame: userFavoriteGameV1;
};

type ApiMap = Record<Api, ApiSet>;

const apiByVersion = {
	[Api.v1]: {
		auth: new authV1(),
		managment: new userV1(),
		dictionary: new dictionaryV1(),
		userFavoriteGame: new userFavoriteGameV1(),
	},
};

export interface ApiGatewayInitParams {
	server: URL;
}

export class ApiGateway extends EventEmitter<EventsMap> {
	private server: URL;
	// private sessionStorage: SessionStorage;
	// private uiManager: UIManager;

	constructor(params: ApiGatewayInitParams) {
		super();
		if (!params.server) {
			throw new Error('Please, provide server Url');
		}
		this.server = params.server;
		// this.uiManager = new UIManager();
		// this.uiManager.onStateChange((s) => {
		// 	switch (s) {
		// 		case UIState.Opened: {
		// 			this.fire(Events.onWidgetStateChanged, new WidgetStateChangedEvent(true));
		// 			break;
		// 		}

		// 		case UIState.Closed: {
		// 			this.fire(Events.onWidgetStateChanged, new WidgetStateChangedEvent(false));
		// 			break;
		// 		}
		// 	}
		// });

		// this.sessionStorage = params.sessionStorage
		// 	? params.sessionStorage
		// 	: new SessionStorage(params.server, {
		// 			storage: params.tokenStorage,
		// 			receiveInIframe: params.sessionStorageInitParams?.receiveInIframe,
		// 			broadcastToIframe: params.sessionStorageInitParams?.broadcastToIframe,
		// 		});

		// this.sessionStorage.onUpdate(() => {
		// 	this.fire(Events.onAuthStateChanged, new AuthStateChangedEvent(this.sessionStorage.isAuthorised()));
		// });
	}

	getAuthApi(api: Api): Promise<ApiSet> {
		Object.keys(apiByVersion[api]).forEach((k) => {
			apiByVersion[api][k as keyof ApiSet].setServer(this.server);
			// apiByVersion[api][k].setSessionStorage(this.sessionStorage);
		});
		return Promise.resolve(apiByVersion[api]);
	}

	getUserGameDataApi(params: UserGameDataApiInitParams): UserGameDataApi {
		return new UserGameDataApi(params);
	}

	// getToken(): Promise<string> {
	// 	return this.sessionStorage.getToken();
	// }

	// restoreState(): Promise<void> {
	// 	return this.sessionStorage.restoreState();
	// }

	// checkAuthorization() {
	// 	this.sessionStorage.checkAuthStatus();
	// }

	// openWidget() {
	// 	this.uiManager.setState(UIState.Opened);
	// }

	// closeWidget() {
	// 	this.uiManager.setState(UIState.Closed);
	// }

	// getSessionStorage() {
	// 	return this.sessionStorage;
	// }

	// logout() {
	// 	this.sessionStorage.logout();
	// }
}
