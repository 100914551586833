import React from 'react';
import { Root, Portal, Overlay, Content, Close, Trigger, Title, Description } from '@radix-ui/react-dialog';
import styles from './styles.module.css';
import { StaticImage } from '../StaticImage';
export type TBaseModalProps = {
  open: boolean;
  onSetOpen: (open: boolean) => void;
};
type TModalProps = TBaseModalProps & {
  ctaButtonText: string;
  title: string;
  description?: React.JSX.Element;
  content: React.JSX.Element;
};
//TODO: revise react.memo here
const Modal = React.memo(({
  onSetOpen,
  open,
  ctaButtonText,
  title,
  description,
  content
}: TModalProps) => <Root open={open} onOpenChange={onSetOpen}>
		<Trigger asChild className={styles.Trigger}>
			<button>{ctaButtonText}</button>
		</Trigger>
		<Portal>
			<Overlay className={styles.Overlay} />

			<Content className={styles.Content}>
				<Title className={styles.Title}>{title}</Title>
				<Description className={styles.Description}>{description}</Description>
				{content}
				<Close asChild>
					<button className={styles.Close} aria-label='Close'>
						<StaticImage className={styles.CloseIcon} src={'/assets/x-close.svg'} alt={'Close'} width={40} height={40} />
					</button>
				</Close>
			</Content>
		</Portal>
	</Root>);
Modal.displayName = 'Modal';
export { Modal };