import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './ChallengeCaptchaModal.module.css';
import { useDeviceDetector } from '@/libs/utils/userAgentContext';
import CloseIcon from '@/components/CloseIcon';
type TModalProps = {
  modalClassName?: string;
  onClose?: () => void;
  children: ReactNode;
  isVisible: boolean;
};
export const ChallengeCaptchaModal = ({
  onClose,
  modalClassName,
  children,
  isVisible
}: TModalProps) => {
  // const isDesktop = DeviceUtils.isDesktopDevice();
  const {
    isDesktop
  } = useDeviceDetector();
  const isDesktopDetected = isDesktop();
  const closeModal = (event: React.MouseEvent<Element, MouseEvent>) => {
    const eventTarget = event.target as Element;
    const currentTarget = event.currentTarget;
    const isClickedOnOverlay = currentTarget === event.target;
    const isClickedOnModalWrapper = eventTarget.classList.contains('Modal__modalWrapper');
    if (isClickedOnOverlay || isClickedOnModalWrapper) {
      onClose?.();
    }
  };
  //TODO: switch to radix-ui dialog component
  return <div className={classNames({
    [styles.captchaModalWrapper]: isVisible,
    [styles.overlay]: isVisible,
    [styles.mobile]: !isDesktopDetected
  })} onClick={closeModal} role='presentation' data-sentry-component="ChallengeCaptchaModal" data-sentry-source-file="ChallengeCaptchaModal.tsx">
			<div className={classNames({
      [styles.mobile]: !isDesktopDetected
    })}>
				<div className={classNames(modalClassName, {
        [styles.modal]: isVisible,
        [styles.mobile]: !isDesktopDetected
      })}>
					{isVisible && <button type='button' onClick={onClose}>
							<CloseIcon className={styles.closeIcon} />
						</button>}
					{children}
				</div>
			</div>
		</div>;
};