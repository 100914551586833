import React, { LegacyRef } from 'react';
import classNames from 'classnames';
import styles from './CaptchaIframe.module.css';
import { logger } from '@/libs/utils/logger';
type TCaptchaIframeProps = {
  iframeRef: LegacyRef<HTMLIFrameElement> | null;
  iframeURL: string;
  setIsIframeCaptchaLoaded: (value: boolean) => void;
  isVisible: boolean;
};
export const CaptchaIframe = ({
  isVisible,
  iframeRef,
  iframeURL,
  setIsIframeCaptchaLoaded
}: TCaptchaIframeProps) => {
  logger.debug('iframe rendered', iframeRef);
  return <div className={classNames({
    [styles.contentWrapper]: isVisible,
    [styles.invisibleCaptcha]: !isVisible
  })} data-sentry-component="CaptchaIframe" data-sentry-source-file="CaptchaIframe.tsx">
			<div className='modal-content'>
				<h2>
					<strong>Verify you&apos;re human</strong>
				</h2>
				<iframe ref={iframeRef} onLoad={() => setIsIframeCaptchaLoaded(true)} src={iframeURL} title='Recaptcha for Eagle' className={styles.recaptchaIframe} />
			</div>
		</div>;
};