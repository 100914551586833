import { Dropdown } from '@/components/Dropdown';
import { getLocaleFromPathname } from '@/root/libs/utils/url';
import { useRouter } from 'next/navigation';
type TUserDropdownProps = {
  username: string | null | undefined;
  onLogout: () => void;
};
export const profileRoutes = {
  'My profile': '/profile'
};
export const UserDropdown = ({
  username,
  onLogout
}: TUserDropdownProps) => {
  const router = useRouter();
  const onUpdate = (value: keyof typeof profileRoutes) => {
    const route = profileRoutes[value];
    const locale = getLocaleFromPathname(window.location.pathname);
    if (window.location.pathname.includes(route)) {
      return;
    }
    router.push(`/${locale}/${route}`);
  };
  return <Dropdown username={username} onLogout={onLogout} items={Object.keys(profileRoutes)} type={'user'} onUpdate={onUpdate} data-sentry-element="Dropdown" data-sentry-component="UserDropdown" data-sentry-source-file="index.tsx" />;
};