export enum FeatureType {
	REGISTER = 'REGISTER',
	LOGIN = 'LOGIN',
	PROFILE = 'PROFILE',
}

export type ResponseError = {
	message: Array<string> | string;
	statusCode: number;
	errorCode: number;
	feature: keyof typeof FeatureType;
};
