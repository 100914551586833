'use client';

import { logger } from '@/libs/utils/logger';
import { Root, Field, Label, Message, Control, Submit } from '@radix-ui/react-form';
import styles from './form.styles.module.css';
import { resendConfirmation } from '@/libs/api/register';
import { AuthType } from '@/libs/api/auth';
import { useEffect, useState } from 'react';
import { CaptchaIframe, RECAPTCHA_ACTIONS, RECAPTCHA_MODES, useCaptcha } from '../recaptcha';
import { ResendConfirmationDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/resend-confirmation.dto';
import { UserApiErrorV1 } from '@/libs/api/eagle-api-wrapper/api-gateway';
type TResendConsfirmEmailFormProps = {
  onSuccess: () => void;
  captchaData: ReturnType<typeof useCaptcha>;
};
export const ResendConfirmEmailForm = ({
  onSuccess,
  captchaData
}: TResendConsfirmEmailFormProps) => {
  const [formData, setFormData] = useState<ResendConfirmationDTO>({
    email: '',
    authType: AuthType.EmailPassword
  });
  const [errors, setErrors] = useState([] as string[]);
  const [errorCode, setErrorCode] = useState<UserApiErrorV1 | null>(null);
  const action = async (formData: FormData) => {
    setErrors([]);
    const data = Object.fromEntries(formData);
    logger.debug({
      data
    });
    const email = data.email as string;
    setFormData(prev => ({
      ...prev,
      email
    }));
    getCaptchaToken(RECAPTCHA_ACTIONS.CONFIRMATION_RESEND);
  };
  const {
    captchaToken,
    captchaTokenError,
    clearCaptchaData,
    getCaptchaToken,
    iframeRef,
    iframeURL,
    setIsIframeCaptchaLoaded,
    showChallengeRecaptcha,
    setShowChallengeRecaptcha
  } = captchaData;
  useEffect(() => {
    if (captchaToken) {
      (async () => {
        const res = await resendConfirmation({
          email: formData.email,
          authType: AuthType.EmailPassword,
          captchaToken
        }); //TODO: react to authType
        if (res && 'errorCode' in res) {
          setErrors(res.message as string[]);
          setErrorCode(res.errorCode);
          return;
        }
        onSuccess();
        clearCaptchaData();
      })();
    }
  }, [captchaToken]);
  useEffect(() => {
    if (captchaTokenError) {
      setErrors(errors => [...errors, captchaTokenError]);
    }
  }, [captchaTokenError]);
  useEffect(() => {
    const isChallengeMode = errorCode === UserApiErrorV1.CaptchaScoreIsTooLow;
    if (!isChallengeMode) {
      clearCaptchaData();
    }
    if (errorCode === UserApiErrorV1.UserNotConfirmedError || errorCode === UserApiErrorV1.UserEmailConfirmationCodeIsInvalid) {
      //TODO: handle user confirmation
    }
    setShowChallengeRecaptcha(isChallengeMode);
    if (isChallengeMode) {
      setFormData(formData => ({
        ...formData,
        captchaMode: RECAPTCHA_MODES.CHALLENGE
      }));
    }
  }, [errorCode, clearCaptchaData, setShowChallengeRecaptcha]);
  const renderCaptcha = () => {
    return <CaptchaIframe isVisible={showChallengeRecaptcha} iframeRef={iframeRef} iframeURL={iframeURL} setIsIframeCaptchaLoaded={setIsIframeCaptchaLoaded} data-sentry-element="CaptchaIframe" data-sentry-component="renderCaptcha" data-sentry-source-file="ResendConfirmEmailForm.tsx" />;
  };
  const renderForm = () => {
    return <>
				<Field className={styles.Field} name='email' data-sentry-element="Field" data-sentry-source-file="ResendConfirmEmailForm.tsx">
					<div style={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between'
        }}>
						<Label className={styles.Label} data-sentry-element="Label" data-sentry-source-file="ResendConfirmEmailForm.tsx">Email</Label>
						<Message className={styles.Message} match='valueMissing' data-sentry-element="Message" data-sentry-source-file="ResendConfirmEmailForm.tsx">
							Please enter your email to resend confirmation code
						</Message>
						<Message className={styles.Message} match='typeMismatch' data-sentry-element="Message" data-sentry-source-file="ResendConfirmEmailForm.tsx">
							Please provide a valid email
						</Message>
					</div>
					<Control asChild data-sentry-element="Control" data-sentry-source-file="ResendConfirmEmailForm.tsx">
						<input className={styles.Input} type='email' required />
					</Control>
				</Field>

				<Submit asChild data-sentry-element="Submit" data-sentry-source-file="ResendConfirmEmailForm.tsx">
					<button className={styles.Button} style={{
          marginTop: 10
        }}>
						Resend Confirmation
					</button>
				</Submit>
			</>;
  };
  return <Root className={styles.Root} action={action} data-sentry-element="Root" data-sentry-component="ResendConfirmEmailForm" data-sentry-source-file="ResendConfirmEmailForm.tsx">
			{errors.length > 0 && <div className={styles.Errors}>
					{errors.map((error, index) => <div key={index}>{error}</div>)}
				</div>}
			{!showChallengeRecaptcha ? renderForm() : null}
			{renderCaptcha()}
		</Root>;
};