import { fetchJson } from '@/libs/api/eagle-api-wrapper/utils';
import { UserGameData } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-game-data.dto';

const DEFAULT_API_ROOT = 'https://arenacloud.cdn.arkadiumhosted.com/uup-api-user-data-dev/api/v1';

export type UserGameDataApiInitParams = {
	apiRoot: string;
};

export class UserGameDataApi {
	apiRoot: string;

	// sessionStorage: SessionStorage | null = null;

	constructor(params: UserGameDataApiInitParams) {
		this.apiRoot = params.apiRoot || DEFAULT_API_ROOT;
		// this.sessionStorage = sessionStorage;
	}

	private makeRequest<T>(method: string, url: string, body: Record<any, any> | null, token: string = ''): Promise<T> {
		return fetchJson(url, {
			method,
			...(token
				? {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				: {}),
			...(body
				? {
						body: JSON.stringify({ ...body }),
					}
				: {}),
		}) as Promise<T>;
	}

	get(slug: string, key: string, token: string): Promise<UserGameData> {
		const url = `${this.apiRoot}/user-data/game/${slug}/${key}`;
		return this.makeRequest<UserGameData>('GET', url, null, token);
	}

	save(slug: string, key: string, data: UserGameData, token: string): Promise<string> {
		const url = `${this.apiRoot}/user-data/game/${slug}/${key}`;

		// Trim null and undefined
		if (data && !data.etag) {
			delete data.etag;
		}

		// return this.sessionStorage?.getToken().then((token) => {
		return this.makeRequest<string>('POST', url, data, token);
		// }) as Promise<string>;
	}

	delete(slug: string, key: string, etag: string, token: string): Promise<void> {
		const url = `${this.apiRoot}/user-data/game/${slug}/${key}?etag=${etag}`;
		// return this.sessionStorage?.getToken().then((token) => {
		return this.makeRequest<void>('DELETE', url, null, token);
		// }) as Promise<void>;
	}

	getKeys(slug: string, token: string): Promise<string[]> {
		const url = `${this.apiRoot}/user-data-key/game/${slug}`;
		// return this.sessionStorage?.getToken().then((token) => {
		return this.makeRequest<string[]>('GET', url, null, token);
		// }) as Promise<string[]>;
	}
}
