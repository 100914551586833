import { AuthResponseDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/auth-response.dto';
import { fetchJson } from '@/libs/api/eagle-api-wrapper/utils';
import { UserFavoriteGameDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-favorite-game.dto';
import { UserFavoriteGameCreateDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-favorite-game-create.dto';

export class UserFavoriteGameApi {
	private server?: URL;
	// private sessionStorage?: SessionStorage;

	setServer(server: URL) {
		if (!server) {
			throw new Error('Please, provide server Url');
		}
		this.server = server;
	}

	// setSessionStorage(storage: SessionStorage) {
	// 	this.sessionStorage = storage;
	// }

	private saveCredential(data: AuthResponseDTO) {
		// if (this.sessionStorage) {
		// 	this.sessionStorage.update(SessionStorageOptions.fromV1(data));
		// }
	}

	private makeRequest(method: string, url: string, body: Record<any, any> | null = null, token: string = '') {
		if (!this.server) {
			throw new Error('No server url provided');
		}

		return fetchJson(this.server.toString() + url, {
			method,
			...(token
				? {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				: {}),
			...(body
				? {
						body: JSON.stringify({ ...body }),
					}
				: {}),
		});
	}

	private doPost(url: string, body: Record<any, any>, token: string = '') {
		return this.makeRequest('POST', url, body, token);
	}

	private doPatch(url: string, body: Record<any, any>, token: string = '') {
		return this.makeRequest('PATCH', url, body, token);
	}

	private doDelete(url: string, body: Record<any, any> | null, token: string = '') {
		return this.makeRequest('DELETE', url, body, token);
	}

	private doGet(url: string, token: string = '') {
		return this.makeRequest('GET', url, null, token);
	}

	getUserFavoriteGames(token: string): Promise<UserFavoriteGameDto[]> {
		// if (!this.sessionStorage) {
		// 	return Promise.reject('Please, provide session storage');
		// }
		// return this.sessionStorage.getToken().then((token) => {
		return this.doGet('user-favorite-game', token);
		// });
	}

	addFavoriteGame(data: UserFavoriteGameCreateDto, token: string): Promise<UserFavoriteGameDto[]> {
		// if (!this.sessionStorage) {
		// 	return Promise.reject('Please, provide session storage');
		// }

		// return this.sessionStorage.getToken().then((token) => {
		return this.doPost('user-favorite-game', { ...data }, token);
		// });
	}

	deleteFavoriteGame(gameKey: string, token: string): Promise<UserFavoriteGameDto[]> {
		// if (!this.sessionStorage) {
		// 	return Promise.reject('Please, provide session storage');
		// }

		// return this.sessionStorage.getToken().then((token) => {
		return this.doDelete(`user-favorite-game/${gameKey}`, null, token);
		// });
	}
}
