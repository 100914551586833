'use client';

import classNames from 'classnames';
import { generateCardId } from '../../uikit_to_delete/helper_funcs/generateCardId';
import { TCalltoaction } from './model';
import { ThumbnailIcon } from '../ThumbnailIcon';
import { ETabIndexesOrderingLevels } from '@/types/types';
import { useEffect, useState } from 'react';
import { buttonBackgroundCalculation } from '@/uikit/helper_funcs/buttonBackgroundCalculation';
import { useDebounce } from '@uidotdev/usehooks';
export const ButtonCallToAction = (props: TCalltoaction) => {
  const text: string = props.text || '';
  const iconStr: string = props?.['icon-url'] || '';
  const iconPos: string = props?.['icon-position'] || '';
  const {
    override_button_normal_border,
    override_button_normal_background,
    override_button_normal_background_end,
    override_button_normal_should_use_border,
    override_button_normal_color,
    override_button_hover_border,
    override_button_hover_background,
    override_button_hover_background_end,
    override_button_hover_should_use_border,
    override_button_hover_color,
    override_button_active_color,
    override_button_active_border,
    override_button_active_background,
    override_button_active_background_end,
    override_button_active_should_use_border,
    override_button_focus_color,
    override_button_focus_border,
    override_button_focus_background,
    override_button_focus_background_end,
    override_button_focus_should_use_border,
    override_button_focus_outline,
    override_is_hover_block
  } = props || {};
  const id = `${generateCardId('calltoaction')}-${props?.addToId || ''}`;
  const isIconBefore: boolean = iconPos === 'before';
  const sizeModClass = props?.size ? `__${props.size}` : '__medium';
  const styleModClass = props?.styling ? `__${props.styling}` : '__filled';
  const horPaddingsModClass = props?.styling !== 'text' ? ' padding-24-left padding-24-right' : '';
  const passedClass = props?.addClass ? ` ${props?.addClass}` : '';
  const tabIndex = parseInt(String(props?.tabIndex === undefined ? ETabIndexesOrderingLevels.OTHER_AUTO : props.tabIndex || ETabIndexesOrderingLevels.OTHER_AUTO));
  const getIcon = () => {
    if (!iconStr) return '';
    return <ThumbnailIcon src={iconStr} className={`${text}-button-icon`} alt={''} width={24} height={24} marginStyle={`margin-${isIconBefore ? 'right' : 'left'}: .5rem`} isThumbnail={true} data-sentry-element="ThumbnailIcon" data-sentry-component="getIcon" data-sentry-source-file="index.tsx" />;
  };
  const addOverride = (cssVar: string, value: any) => value ? `${cssVar}: ${value};` : '';

  // Handle overrides with gradient
  const isHoverBlock = override_is_hover_block;
  const isGradientButton = (cssBcgValue: string) => cssBcgValue?.match?.(/linear-gradient/gi);
  const finalBackgrounds = buttonBackgroundCalculation({
    override_button_normal_background,
    override_button_normal_background_end,
    override_button_hover_background,
    override_button_hover_background_end,
    override_button_active_background,
    override_button_active_background_end,
    override_button_focus_background,
    override_button_focus_background_end,
    isHoverBlock
  });
  const {
    bcgNormal,
    bcgHover,
    bcgActive,
    bcgFocus
  } = finalBackgrounds;
  const useBorderNormal = override_button_normal_should_use_border !== false;
  const useBorderHover = override_button_hover_should_use_border !== false;
  const useBorderActive = override_button_active_should_use_border !== false;
  const useBorderFocus = override_button_focus_should_use_border !== false;
  const borderTransparentVal = 'transparent !important';
  const borderNormal = !useBorderNormal ? borderTransparentVal : override_button_normal_border;
  const borderHover = isHoverBlock && isGradientButton(bcgHover) && useBorderHover ? override_button_hover_border ?? '#FFF' : !useBorderHover ? borderTransparentVal : override_button_hover_border;
  const borderActive = isHoverBlock && isGradientButton(bcgActive) && useBorderActive ? override_button_active_border ?? '#FFF' : !useBorderActive ? borderTransparentVal : override_button_active_border;
  const borderFocus = !useBorderFocus ? borderTransparentVal : override_button_focus_border;
  const overrideClass = `${id}__overrides`;
  const overrideClassSelector = `.${overrideClass}`;
  const generateStyling = (): string => {
    return `
		#${id}  {
			background: transparent !important;
			border-radius: 0 !important;
			border: 0 !important;
		}
		#${id}.__text,
		#${id}.__text .button-content {
			background: transparent !important;
			background-color: transparent !important;
			box-shadow: none !important;
			border: none !important;
		}
		#${id}.__text .button-content {
			outline: none !important;
		}

		#${id}.__filled:not(.__text),
			[data-theme="light"] #${id}:not(.__outline):not(.__text) .button-content {
			--button-cta-text-color-default: #FFFFFF;
			--button-cta-bcg-color-default: #000000;
			--button-cta-border-color-default: #000000;
		}
		#${id}.__outline:not(.__text),
		[data-theme="dark"] #${id}:not(.__filled):not(.__text) .button-content {
			--button-cta-text-color-default: #000000;
			--button-cta-bcg-color-default: #FFFFFF;
			--button-cta-border-color-default: #000000;
		}
		${id}.__filled:not(.__text) .button-content,
		[data-theme="light"] #${id}:hover:not(.__outline):not(.__text) .button-content,
		[data-theme="light"] [class*="ark-ui-block-card-hero-"] .card:hover #${id}:not(.__outline):not(.__text) .button-content {
			--button-cta-text-color-default: #FFFFFF;
			--button-cta-bcg-color-default: var(--color-gray-600);
			--button-cta-border-color-default: var(--color-gray-600);
		}
		${id}.__outline:not(.__text) .button-content,
		[data-theme="dark"] #${id}:hover:not(.__filled):not(.__text) .button-content,
		[data-theme="dark"] [class*="ark-ui-block-card"]:not(.ark-ui-block-card-promo) .card:hover #${id}:not(.__filled):not(.__text) .button-content {
			--button-cta-text-color-default: #000000;
			--button-cta-bcg-color-default: var(--color-gray-50);
			--button-cta-border-color-default: var(--color-black);
		}
		${id}.__filled:not(.__text):active .button-content,
		[data-theme="light"] #${id}:active:not(.__outline):not(.__text) .button-content,
		[data-theme="light"] [class*="ark-ui-block-card-hero-"] .card:active #${id}:not(.__outline):not(.__text) .button-content {
			--button-cta-text-color-default: #FFFFFF;
			--button-cta-bcg-color-default: var(--color-gray-500);
			--button-cta-border-color-default: var(--color-gray-500);
		}
		${id}.__outline .button-content,
		[data-theme="dark"] #${id}:active:not(.__filled):not(.__text) .button-content,
		[data-theme="dark"] [class*="ark-ui-block-card"] .card:active #${id}:not(.__filled):not(.__text) .button-content {
			--button-cta-text-color-default: #000000;
			--button-cta-bcg-color-default: var(--color-gray-100);
			--button-cta-border-color-default: var(--color-black);
		}
		[data-theme="light"] #${id}.__text .button-content  {
			--button-cta-text-color-default: var(--color-black);
		}
		[data-theme="dark"] #${id}.__text .button-content  {
			--button-cta-text-color-default: var(--color-white);
		}

		#${id} .button-content${overrideClassSelector} {
			${addOverride('--button-cta-text-color', override_button_normal_color || `var(--button-cta-text-color-default)`)}
			${addOverride('--button-cta-bcg-color', bcgNormal || `var(--button-cta-bcg-color-default)`)}
			${addOverride('--button-cta-border-color', borderNormal || `var(--button-cta-border-color-default)`)}
			${!useBorderNormal && isGradientButton(bcgNormal) ? 'box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);' : `box-shadow: inset 0 0 0 2px var(--button-cta-border-color);`}
			${!isGradientButton(bcgNormal) ? 'box-shadow: none !important;' : 'border: none !important;'}
		}
		[class*="ark-ui-block-card-hero-"]:hover #${id}:not(.__text) .button-content${overrideClassSelector},
		[class*="ark-ui-block-card-standard-"]:hover #${id}:not(.__text) .button-content${overrideClassSelector},
		[class*="ark-ui-block-card-portrait-"]:hover #${id}:not(.__text) .button-content${overrideClassSelector},
		#${id}:hover:not(.__text) .button-content,
			#${id}:not(.__text) .button-content:hover {
			${addOverride('--button-cta-text-color', override_button_hover_color || `var(--button-cta-text-color-default)`)}
			${addOverride('--button-cta-bcg-color', bcgHover || `var(--button-cta-bcg-color-default)`)}
			${addOverride('--button-cta-border-color', borderHover || `var(--button-cta-border-color-default)`)}
			${!useBorderHover && !isHoverBlock && isGradientButton(bcgHover) ? 'box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);' : `box-shadow: inset 0 0 0 2px var(--button-cta-border-color);`}
		}
		[class*="ark-ui-block-card-"]:active #${id}:not(.__text) .button-content${overrideClassSelector},
		.card:active #${id}:not(.__text) .button-content${overrideClassSelector},
		a:active #${id}:not(.__text) .button-content${overrideClassSelector},
		#${id}:active:not(.__text) .button-content${overrideClassSelector},
		#${id}:not(.__text) .button-content${overrideClassSelector}:active {
			${addOverride('--button-cta-text-color', override_button_active_color || `var(--button-cta-text-color-default)`)}
			${addOverride('--button-cta-border-color', borderActive || `var(--button-cta-border-color-default)`)}
			${addOverride('--button-cta-bcg-color', bcgActive || `var(--button-cta-bcg-color-default)`)}
			${!useBorderActive && !isHoverBlock && isGradientButton(bcgActive) ? 'box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);' : `box-shadow: inset 0 0 0 2px var(--button-cta-border-color);`}
		}
		.card:focus:not(:active) #${id}:not(:active) .button-content${overrideClassSelector}:not(:active),
		.card:focus-visible:not(:active) #${id}:not(:active) .button-content${overrideClassSelector}:not(:active),
		#${id}:focus:not(:active) .button-content${overrideClassSelector}:not(:active),
		#${id}:focus-visible:not(:active) .button-content${overrideClassSelector}:not(:active) {
			${addOverride('--button-cta-outline-color', override_button_focus_border || `var(--button-cta-border-color-default)`)}
			${!useBorderFocus && !isHoverBlock && isGradientButton(bcgFocus) ? 'box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);' : `box-shadow: inset 0 0 0 2px var(--button-cta-border-color);`}
		}
		#${id} .button-content svg,
			#${id} .button-content img svg,
			#${id} .button-content svg path,
			#${id} .button-content img svg path {
			stroke: var(--button-cta-text-color, var(--button-cta-text-color-default)) !important;
		}
		#${id} .button-content svg.play-icon-svg {
			fill: var(--button-cta-text-color, var(--button-cta-text-color-default)) !important;
			transform: scale(.7);
		}
		`;
  };
  const [isLoaded, setLoaded] = useState(false);
  const isStable = useDebounce(isLoaded, 200);
  useEffect(() => {
    if (!document.getElementById(`${id}-style`)) {
      const styleTag = document.createElement('style');
      styleTag.id = `${id}-style`;
      styleTag.innerHTML = generateStyling();
      document.head.appendChild(styleTag);
      setTimeout(() => setLoaded(true), 200); // prevent glitches on cls / dynamic styles applied
    }
    // Cleanup the style tag on unmount to prevent memory leaks
    return () => {
      const styleTag = document.getElementById(`${id}-style`);
      styleTag && document.head.removeChild(styleTag);
    };
  }, [id]);
  return <>
			<button id={id} role='button' tabIndex={tabIndex} aria-label={!text ? 'Clickable button' : ''} className={classNames('ark-ui-atom-button-calltoaction-button', styleModClass)} style={{
      visibility: isStable ? 'visible' : 'hidden',
      pointerEvents: isHoverBlock && isGradientButton(bcgNormal) ? 'initial' : undefined
    }} onClick={(ev: any) => props?.onClick && props.onClick(ev)} data-testid={props?.testId}>
				<p className={classNames(sizeModClass, horPaddingsModClass, passedClass, 'button-content', 'text-18', 'padding-8-top', 'padding-8-bottom', overrideClass)}>
					{isIconBefore ? getIcon() : ''}
					{!text ? <slot></slot> : text}
					{!isIconBefore ? getIcon() : ''}
				</p>
			</button>
		</>;
};