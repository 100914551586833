export class EventEmitter<P> {
	events: Record<any, any[]> = {};
	addEventListener<T extends keyof P, R extends P[T]>(event: T, cb: (data?: R) => void) {
		if (this.events[event]) {
			this.events[event].push(cb);
			return;
		}
		this.events[event] = [cb];
	}

	removeEventListener<T extends keyof P, R extends P[T]>(event: T, cb: (data?: R) => void) {
		if (this.events[event]) {
			this.events[event] = this.events[event].filter((fn) => fn !== cb);
		}
	}

	protected fire<T extends keyof P, R extends P[T]>(event: T, data: R | undefined = undefined) {
		(this.events[event] || []).forEach((cb) => cb(data));
	}
}
