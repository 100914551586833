'use client';

import { Root, Field, Label, Control, Submit } from '@radix-ui/react-form';
import styles from '../form.styles.module.css';
import { ButtonCallToAction } from '@/components/ButtonCallToAction';
import { TAuthFormAction } from '../EntryForm';
export type TSignInPasswordFormProps = {
  action: TAuthFormAction;
  errors: string[];
  email: string | null;
};
export const SignInPasswordForm = ({
  action,
  email,
  errors
}: TSignInPasswordFormProps) => {
  return <Root className={styles.Root} action={action} data-sentry-element="Root" data-sentry-component="SignInPasswordForm" data-sentry-source-file="index.tsx">
			{/* SOCIAL LOGINS PLACEHOLDER*/}
			<Field className={styles.Field} name='email' data-sentry-element="Field" data-sentry-source-file="index.tsx">
				<div style={{
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between'
      }}>
					<Label className={styles.Label} data-sentry-element="Label" data-sentry-source-file="index.tsx">
						Ready to play? Sign in with <strong>[{email}]</strong>
					</Label>
				</div>
				<Control asChild data-sentry-element="Control" data-sentry-source-file="index.tsx">
					<input className={styles.Input} name='password' type='password' />
				</Control>
				{errors.map(error => <p key={error} className={styles.Error}>
						{error}
					</p>)}
			</Field>
			<Submit asChild data-sentry-element="Submit" data-sentry-source-file="index.tsx">
				<ButtonCallToAction text='Continue' addClass={styles.ContinueButton} data-sentry-element="ButtonCallToAction" data-sentry-source-file="index.tsx"></ButtonCallToAction>
			</Submit>
			{/* PRIVACY POLICY PLACEHOLDER*/}
		</Root>;
};