import { useEffect, useRef, useState } from 'react';
enum EBUTTON_TYPES {
  EAGLE_BUTTON = 'EAGLE_BUTTON',
}
export const FacebookAuthButton = () => {
  const ref = useRef<HTMLIFrameElement>(null);
  const [isButtonLoad, setIsButtonLoad] = useState<boolean>(false);
  const authButtonUrl = process.env.FACEBOOK_AUTH_BUTTON_URL;
  const postMessageToButton = ({
    type,
    target,
    origin,
    payload = {}
  }: {
    type: string;
    target: HTMLIFrameElement;
    origin: string;
    payload?: {
      googleAppId?: string;
      innerWidth?: number;
    };
  }) => {
    target?.contentWindow?.postMessage({
      type,
      payload
    }, origin);
  };
  useEffect(() => {
    // Post message to db and google
    if (ref?.current && isButtonLoad) {
      postMessageToButton({
        type: EBUTTON_TYPES.EAGLE_BUTTON,
        target: ref.current,
        origin: authButtonUrl,
        payload: {
          innerWidth: window.innerWidth
        }
      });
    }
  }, [authButtonUrl, isButtonLoad]);
  return <iframe ref={ref} onLoad={() => setIsButtonLoad(true)} src={authButtonUrl} title='facebook button iframe' id='facebook-button-iframe' height='40px' width='220px' data-sentry-component="FacebookAuthButton" data-sentry-source-file="index.tsx" />;
};