'use client';

import { Modal } from '@/components/Modal';
import React from 'react';
export const EntryModal = (props: {
  isLoggedIn: boolean;
  onLogout: () => void;
  username: string | undefined | null;
  open: boolean;
  onSetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  content: React.JSX.Element;
}) => {
  return <Modal open={props.open} onSetOpen={props.onSetOpen} ctaButtonText={'Login'} title={'Login'} content={props.content} data-sentry-element="Modal" data-sentry-component="EntryModal" data-sentry-source-file="EntryModal.tsx" />;
};