export enum UserApiError {
	InvalidRequestData = 1000,
	UserNotFound = 1001,
	UserAlreadyExist,
	UserIsNotActive,
	UserIsBanned,
	UserIsSoftDeleted,
	UserRemovedViaGDPR,
	EmailDoesNotSpecified,
	EmailAlreadyUsed,
	UserNameAlreadyUsed,
	RefreshTokenIsInvalid,
	EmailOrPasswordIsIncorrect,
	PasswordTooEasy,
	CountryIsInvalid,
	LanguageIsInvalid,
	UserOldPasswordIsIncorrect,
	NewAndOldPasswordAreEquals,
	UserPasswordsAreNotEqual,
	UserNotConfirmedError,
	UserEmailConfirmationCodeIsInvalid,
	UserPasswordResetCodeIsInvalid,
	TooManyFailedLoginAttempts,
	CaptchaValidationFailed,
	CaptchaScoreIsTooLow,
	EmailSenderGeneralError = 2000,
	EmailSenderEmailContentIsEmpty,
	ExternalProviderError,
	NoEmailChangeRequest,
	EmailChangeRequestCodeIsInvalid,
	UserHasNoActiveSubscriptions,
	SocialPasswordAlreadyExists,
	EmailsShouldMatch,
	ApplicationNotFound,
	ExternalAuthApplicationIsNotAllowed,
	ExternalAuthTokenIsInvalid,
}
